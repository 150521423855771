import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-22cae88e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "qrcode-container"
};
const _hoisted_2 = {
  class: "qrcode-wrapper"
};
const _hoisted_3 = {
  class: "qrcode-text"
};
import Qrcode from 'qrcode.vue';
export default {
  props: {
    url: {
      type: String,
      required: true
    },
    desc: {
      type: String,
      default: '扫码查看详情'
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:show'],
  setup(__props, {
    emit
  }) {
    const handleClose = () => {
      emit('update:show', false);
    };
    return (_ctx, _cache) => {
      const _component_van_icon = _resolveComponent("van-icon");
      const _component_van_popup = _resolveComponent("van-popup");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_popup, {
        show: __props.show,
        position: "bottom",
        style: {
          "height": "100%"
        },
        "onUpdate:show": _cache[0] || (_cache[0] = val => _ctx.$emit('update:show', val))
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_van_icon, {
          name: "cross",
          class: "close-icon",
          onClick: handleClose
        }), _createVNode(Qrcode, {
          class: "qrcode",
          value: __props.url,
          size: 300,
          margin: 5
        }, null, 8, ["value"]), _createElementVNode("div", _hoisted_3, _toDisplayString(__props.desc), 1)])]),
        _: 1
      }, 8, ["show"])]);
    };
  }
};