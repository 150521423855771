import { getRedNoteProInfo } from "@/api";
import { mdToast } from "../../utils/tools";
import env from "md-base-tools/env";
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ifShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      }
    },
    isMobile() {
      return env.isMobile();
    }
  },
  mounted() {
    this.sendUpdateInfoRequest();
  },
  data() {
    return {
      is_loading: true,
      hava_pro: false,
      pro_title: '',
      pro_id: '',
      pro_status_str: ''
    };
  },
  methods: {
    async sendUpdateInfoRequest() {
      const {
        status,
        data,
        message
      } = await getRedNoteProInfo({});
      this.is_loading = false;
      if (status == 0) {
        if (data.pro_id) {
          this.hava_pro = true;
          this.pro_id = data.pro_id;
          this.pro_title = data.title;
          this.pro_status_str = data.status;
        }
      } else {
        mdToast(message);
      }
    },
    btnAction(event, index) {
      if (1 == index) {
        this.$emit('guideToPCUpdateAction', {
          'event': event,
          'pro_id': this.pro_id
        });
      } else {
        this.ifShow = false;
      }
    }
  }
};