export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ifShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      }
    }
  },
  mounted() {},
  data() {
    return {};
  },
  methods: {
    btnAction() {
      this.ifShow = false;
    }
  }
};